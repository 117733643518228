import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import classnames from 'classnames';

import Layout from '../../components/layout/layout';
import RichText from '../../components/rich-text/rich-text';
import Seo from '../../components/seo/seo';

import Txt from '../../images/txt.inline.svg';
import Mic from '../../images/mic.inline.svg';
import Vid from '../../images/vid.inline.svg';
import Calendar from '../../images/calendar.inline.svg';
import Facebook from '../../images/share-fb.inline.svg';
import Twitter from '../../images/share-tw.inline.svg';
import Linkedin from '../../images/share-in.inline.svg';

import dateFormatter from '../../utils/dateFormatter';
import google from '../../images/podcast-google.svg';
import apple from '../../images/podcast-apple.svg';
import spotify from '../../images/podcast-spotify.svg';
import soundcloud from '../../images/podcast-soundcloud.svg';

import {
  main,
  container,
  header,
  meta,
  typeList,
  catList,
  postTitle,
  postDate,
  podcastCont,
  postPodcasts,
  postImg,
  postCont,
  shareList,
} from './post.module.scss';

const PostTemplate = ({ data }) => {
  const { title, date, categories, seo } = data.wp.post;
  const { postType, description, image, text, podcast, video } =
    data.wp.post.data;
  const podcastApps = [
    {
      url: podcast.google,
      icon: google,
      alt: 'Listen on Google podcast',
    },
    {
      url: podcast.apple,
      icon: apple,
      alt: 'Listen on Apple podcast',
    },
    {
      url: podcast.spotify,
      icon: spotify,
      alt: 'Listen on Spotigy',
    },
    {
      url: podcast.soundcloud,
      icon: soundcloud,
      alt: 'Listen on Soundcloud',
    },
  ];
  const podcastList = podcastApps.filter((item) => item.url !== null);

  console.log(seo.canonical);

  const share = [
    {
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        seo.canonical
      )}`,
      icon: <Facebook />,
    },
    {
      url: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        `${seo.title} ${seo.canonical}`
      )}`,
      icon: <Twitter />,
    },
    {
      url: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        seo.canonical
      )}&title=${encodeURIComponent(seo.title)}&summary=&source=`,
      icon: <Linkedin />,
    },
  ];

  return (
    <>
      <Seo {...seo} />
      <Layout>
        <article className={main}>
          <div className={classnames('container', container)}>
            <div className={header}>
              <div className={meta}>
                {postType && (
                  <ul className={typeList}>
                    {postType.map((postType) => {
                      return (
                        <li key={postType}>
                          {postType === 'text' && <Txt />}
                          {postType === 'video' && <Vid />}
                          {postType === 'podcast' && <Mic />}
                        </li>
                      );
                    })}
                  </ul>
                )}
                <ul className={catList}>
                  {categories?.nodes?.map((category) => {
                    return <li key={category.slug}>{category.name}</li>;
                  })}
                </ul>
              </div>
              <h1 className={postTitle}>{title}</h1>
              {description && (
                <div dangerouslySetInnerHTML={{ __html: description }} />
              )}
              <div className={postDate}>
                <Calendar />
                <time time={date.replace('T', ' ')}>{dateFormatter(date)}</time>
              </div>
              {postType.includes('podcast') && (
                <div className={podcastCont}>
                  {podcast.file !== null && (
                    <audio className="js-player" controls>
                      <source
                        src={podcast.file.mediaItemUrl}
                        type="audio/mp3"
                      />
                    </audio>
                  )}
                  {podcastList.length > 0 && (
                    <ul className={postPodcasts}>
                      {podcastList.map((item, idx) => {
                        return (
                          <li key={idx}>
                            <a href={item.url} target="_blank" rel="noreferrer">
                              <img src={item.icon} alt={item.alt} />
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              )}
              {postType.includes('video') && (
                <div
                  className="js-player"
                  data-plyr-provider={video.provider}
                  data-plyr-embed-id={video.id}
                />
              )}
              {!postType.includes('video') && (
                <GatsbyImage
                  image={image.imageFile.childImageSharp.gatsbyImageData}
                  alt={image.altText}
                  className={postImg}
                />
              )}
            </div>
            <div className={postCont}>
              <div>
                <div className={shareList}>
                  {share.map((item, idx) => {
                    return (
                      <a
                        key={idx}
                        href={item.url}
                        target={'_blank'}
                        rel={'noreferrer'}
                      >
                        {item.icon}
                      </a>
                    );
                  })}
                </div>
              </div>
              <RichText>{text}</RichText>
            </div>
          </div>
        </article>
      </Layout>
    </>
  );
};

export default PostTemplate;

export const query = graphql`
  query ($slug: ID!) {
    wp {
      post(idType: SLUG, id: $slug) {
        title
        date
        categories {
          nodes {
            name
            slug
          }
        }
        data {
          description
          image {
            sourceUrl
            altText
            imageFile {
              childImageSharp {
                gatsbyImageData(width: 994, height: 508)
              }
            }
          }
          postType
          text
          video {
            id
            provider
          }
          podcast {
            file {
              mediaItemUrl
            }
            apple
            google
            soundcloud
            spotify
          }
        }
        seo {
          title
          canonical
          cornerstone
          focuskw
          metaDesc
          metaKeywords
          metaRobotsNofollow
          metaRobotsNoindex
          opengraphAuthor
          opengraphDescription
          opengraphImage {
            sourceUrl
            mediaDetails {
              width
              height
            }
            mimeType
          }
          opengraphModifiedTime
          opengraphPublishedTime
          opengraphPublisher
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          readingTime
          twitterDescription
          twitterImage {
            sourceUrl
            mediaDetails {
              width
              height
            }
            mimeType
          }
          twitterTitle
          schema {
            articleType
            pageType
            raw
          }
        }
      }
    }
  }
`;
